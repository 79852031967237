import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)
const routes = {
  path: "",
  component: () =>
    import("../../../layouts/main/Main.vue"),
  children: [
    {
      path: "/matches",
      name: "matches",
      beforeEnter: (to, from, next) => {
        const menuName = "Nova consulta";
        const bearer = Vue.prototype.$session.get("bearer");
        const menus = Vue.prototype.$session.get("menus-sidebar");
        const isProjectPlan = Vue.prototype.$session.get("isProject") ? Vue.prototype.$session.get("isProject") : false;
        let accessAllowed = true;
        for (const m in menus) {
          if (menus[m].rota === menuName) {
            accessAllowed = menus[m].ativaRota;
          }
        }
        let isAuthenticated = false;
        if (bearer !== null && bearer !== undefined && bearer !== "") {
          isAuthenticated = true;
        }
        else {
          isAuthenticated = false;
        }

        if (!isAuthenticated)
          if (isProjectPlan) next("/login-horas")
          else next("/login")
        else if (!accessAllowed) next("/access-denied")
        else next()
      },
      component: () =>
        import("./match/match.vue"),
    },
    {
      path: "/home",
      name: "home",
      beforeEnter: (to, from, next) => {
        const bearer = Vue.prototype.$session.get("bearer");
        const servicesRoutes = Vue.prototype.$session.get("servicesRoutes")
        const hasSolicitationRequestPermission = servicesRoutes.includes("/solicitation-and-exams");

        if (!bearer) {
          if (Vue.prototype.$session.get("isProject")) {
            next("/login-horas")
          } else {
            next("/login")
          }
        } else if (!hasSolicitationRequestPermission) {
          next("/pendency")
        } else {
          next()
        }
      },
      component: () =>
        import("./home/home.vue"),
    },
  ],
}
export default [
  routes,
]

