<template>
	<div id="app">
		<router-view />
	</div>  
</template>

<script>
import { useStore } from "@/store/store.js"
import themeConfig from "@/../themeConfig.js"

export default {
  name: "App",

  setup () {
    const store = useStore()

    return {
      setWindowWidth: (size) => store.dispatch("updateWindowWidth", size),
      setEnviroment: (url) => store.dispatch("setEnviroment", url),
    }
  },

  async created() {
    await this.setEnviroment(window.location.origin)
  },

  async mounted() {    
    this.toggleClassInBody(themeConfig.theme)
    
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize)
    })

    await this.handleWindowResize()
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize)
  },

  methods: {  
    toggleClassInBody(className) {
      if (className == "dark") {
        if (document.body.className.match("theme-semi-dark")) document.body.classList.remove("theme-semi-dark")
        document.body.classList.add("theme-dark")
      } else if (className == "semi-dark") {
        if (document.body.className.match("theme-dark")) document.body.classList.remove("theme-dark")
        document.body.classList.add("theme-semi-dark")
      } else {
        if (document.body.className.match("theme-dark")) document.body.classList.remove("theme-dark")
        if (document.body.className.match("theme-semi-dark")) document.body.classList.remove("theme-semi-dark")
      }
    },
    async handleWindowResize() {
      await this.setWindowWidth(document.documentElement.clientWidth)
    },
  },
  
  watch: {
    "$store.state.theme"(val) {
      this.toggleClassInBody(val)
    },
  },
}

</script>
