import axios from "axios";
import Vue from "vue";
import router from "../router/router";
let expiredNotification = false;
const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_BACK,
})
api.interceptors.request.use(
  config => {
    const token = Vue.prototype.$session.get("bearer");
    if (token && config.url.includes(config.baseURL)) {
      config.headers.common["Authorization"] = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  response => {
    if (response.status < 400) {
      setTimeout(() => { if( process.env.NODE_ENV === "production") console.clear()}, 50);
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          if (!error.response.config.url.includes("login")) {
            if (!expiredNotification) {
              expiredNotification = true;
              Vue.prototype.$vs.notify({
                title: "Atenção",
                text: "Sua sessão expirou, favor realizar o login novamente",
                color: "warning",
                icon: "error",
                time: 4000,
                position: "top-center",
              });
              setTimeout(() => expiredNotification = false, 4000);
            }
            return router.push("/logout");
          }
      }
      setTimeout(() => { if( process.env.NODE_ENV === "production") console.clear()}, 50);
      return Promise.reject(error.response);
    }
  }
);
export default api