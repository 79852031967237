export default [
  {
    path: "",
    name: "login",
    component: () => import("./pages/login/login.vue"),
  },
  {
    path: "/login-horas",
    name: "login-horas",
    component: () => import("./pages/login/login.vue"),
  },
  {
    path: "/login",
    name: "page-login",
    component: () => import("./pages/login/login.vue"),
  },
  {
    path: "/login/:token",
    name: "page-auth-login",
    component: () => import("./pages/login/login.vue"),
  },
  {
    path: "/login/:medRecId",
    name: "page-login-room",
    component: () => import("./pages/login/login.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./pages/logout/logout.vue"),
  },
  //FLUXO DASA
  {
    path: "/first-access",
    name: "first-access",
    component: () => import("./pages/firstAccess/firstAccess.vue"),
  },
  //FLUXO FILOO
  {
    path: "/first-access/:cpf",
    name: "first-access",
    component: () => import("./pages/firstAccess/firstAccess.vue"),
  },
  {
    path: "/finalize-registration",
    name: "finalize-registration",
    component: () =>
      import(
        "./pages/firstAccess/finalizeRegistration/finalizeRegistration.vue"
      ),
    props: true,
  },
  {
    path: "*",
    name: "error404",
    component: () => import("./pages/errors/error404/error404.vue"),
  },
  {
    path: "/access-denied",
    name: "error403",
    component: () => import("./pages/errors/error403/error403.vue"),
  },
  {
    path: "/register-with-token",
    name: "register-with-token",
    component: () =>
      import("./pages/firstAccess/registerWithToken/RegisterWithToken.vue"),
  },
];
