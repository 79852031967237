import Vue from "vue"
import Router from "vue-router"
import routes from "./routes"

import { useStore } from "@/store/store"

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,

})

router.beforeEach(async (to, from, next) => {
  let bearer = Vue.prototype.$session.get("bearer")

  if (bearer) {
    if (bearer.indexOf(" ") >= 0) {
      bearer = bearer.split(" ")[1]
    }

    const store = await useStore()
    const user = await store.state.user
    const filooUrl = store.state.filooPortalClientUrl

    if (user) {
      const { isDasa, customerPlan: { code, multPlanCode } } = user
      const checkCode = code ? code.toLowerCase().includes("dasa") : false
      const checkMultiplan = multPlanCode ? multPlanCode.toLowerCase().includes("dasa") : false
  
      if (!isDasa && !checkCode && !checkMultiplan) {
        window.location.href = `${filooUrl}#/login/${bearer}`

        return
      }
    }
  }

  next()
})

router.afterEach(() => {
  const appLoading = document.getElementById("loading-bg")

  if (appLoading) {
    appLoading.style.display = "none"
  }

})

export default router
