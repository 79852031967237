import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)
const routes = {
  path: "",
  component: () =>
    import("../../../layouts/main/Main.vue"),
  children: [
    {
      path: "/plan-upgrade",
      name: "plan-upgrade",
      props: true,
      beforeEnter: (to, from, next) => {
        const menuName = "Meu Plano";
        const bearer = Vue.prototype.$session.get("bearer");
        const menus = Vue.prototype.$session.get("menus-sidebar");
        // const customerData = Vue.prototype.$session.get("customerData")
        const isProjectPlan = Vue.prototype.$session.get("isProject")
          ? Vue.prototype.$session.get("isProject")
          : false;
        
        // const companyId = customerData ? customerData.companyId : false

        let accessAllowed = true;

        for (const m in menus) {
          if (menus[m].rota === menuName) {
            accessAllowed = menus[m].ativaRota;
          }
        }
        let isAuthenticated = false;
        if (bearer !== null && bearer !== undefined && bearer !== "") {
          isAuthenticated = true;
        }
        else {
          isAuthenticated = false;
        }
        if (!isAuthenticated) 
          if (isProjectPlan) next("/login-horas")
          else next("/login")
        else if (!accessAllowed) next("/access-denied")
        // else if (companyId !== 2) next("/home")
        else next()
      },
      component: () =>
        import("./planUpgrade/optionsScreen/optionsScreen.vue"),
    },
    {
      path: "/products/know-more",
      name: "products-know-more",
      props: true,
      beforeEnter: (to, from, next) => {
        const menuName = "Meu Plano";
        const bearer = Vue.prototype.$session.get("bearer");
        const menus = Vue.prototype.$session.get("menus-sidebar");
        const companyId = Vue.prototype.$session.get("customerData").companyId;
        const isProjectPlan = Vue.prototype.$session.get("isProject") ? Vue.prototype.$session.get("isProject") : false;
        let accessAllowed = true;

        for (const m in menus) {
          if (menus[m].rota === menuName) {
            accessAllowed = menus[m].ativaRota;
          }
        }
        let isAuthenticated = false;
        if (bearer !== null && bearer !== undefined && bearer !== "") {
          isAuthenticated = true;
        }
        else {
          isAuthenticated = false;
        }
        if (!isAuthenticated) 
          if (isProjectPlan) next("/login-horas")
          else next("/login")
        else if (!accessAllowed) next("/access-denied")
        else if (companyId !== 2) next("/home")
        else next()
      },
      component: () =>
      import("./planUpgrade/products/products.vue"),
    },
    {
      path: "/shopping-cart",
      name: "shopping-cart",
      props: true,
      beforeEnter: (to, from, next) => {
        const menuName = "Meu Plano";
        const bearer = Vue.prototype.$session.get("bearer");
        const menus = Vue.prototype.$session.get("menus-sidebar");
        const companyId = Vue.prototype.$session.get("customerData").companyId;
        const isProjectPlan = Vue.prototype.$session.get("isProject") ? Vue.prototype.$session.get("isProject") : false;
        let accessAllowed = true;

        for (const m in menus) {
          if (menus[m].rota === menuName) {
            accessAllowed = menus[m].ativaRota;
          }
        }
        let isAuthenticated = false;
        if (bearer !== null && bearer !== undefined && bearer !== "") {
          isAuthenticated = true;
        }
        else {
          isAuthenticated = false;
        }
        if (!isAuthenticated) 
          if (isProjectPlan) next("/login-horas")
          else next("/login")
        else if (!accessAllowed) next("/access-denied")
        else if (companyId !== 2) next("/home")
        else next()
      },
      component: () =>
        import("./planUpgrade/shoppingCart/shoppingCart.vue"),
    },
    {
      path: "/plan-downgrade",
      name: "plan-downgrade",
      props: true,
      beforeEnter: (to, from, next) => {
        const menuName = "Meu Plano";
        const bearer = Vue.prototype.$session.get("bearer");
        const menus = Vue.prototype.$session.get("menus-sidebar");
        const companyId = Vue.prototype.$session.get("customerData").companyId;
        const isProjectPlan = Vue.prototype.$session.get("isProject") ? Vue.prototype.$session.get("isProject") : false;
        let accessAllowed = true;

        for (const m in menus) {
          if (menus[m].rota === menuName) {
            accessAllowed = menus[m].ativaRota;
          }
        }
        let isAuthenticated = false;
        if (bearer !== null && bearer !== undefined && bearer !== "") {
          isAuthenticated = true;
        }
        else {
          isAuthenticated = false;
        }
        if (!isAuthenticated) 
          if (isProjectPlan) next("/login-horas")
          else next("/login")
        else if (!accessAllowed) next("/access-denied")
        else if (companyId !== 2) next("/home")
        else next()
      },
      component: () =>
        import("./planDowngrade/planDowngrade.vue"),
    },
  ],
}

export default [
  routes,
]

