import Vue from "vue"
import axios from "axios"

/**
 * Checks patient elegibility for a campaign.
 * @param {number} patientId - The id of the patient.
 * @param {array[{ plans: array[number], sevices[number]}]} ids - The id of the service or plan.
 * @returns {Object.<string, (boolean|string|undefined)|undefined>} Returns an object with its elegibility status and a message if not elegible. Otherwise, on upstream error, returns undefined.
 */
export const checkCampaignElegibility = async (patientId, ids) => {
  try {
    const endPoint = `patient/validate/eligibility/${patientId}`
    const { plans, services: servs } = ids

    const { data, status } = await axios.get(
      `${process.env.VUE_APP_BASE_URL_BACK}${endPoint}`,
      { headers: { Authorization: Vue.prototype.$session.get("bearer") }}
    )

    if (status === 200) {
      if (data.valid) {
        if (plans && data.plans && data.plans.length) {
          data.plans = data.plans.filter((id) => plans.includes(id))
          data.valid = !!data.plans.length

          if (!data.valid) {
            data.message = `Ops! Parece que você ja possui esse plano! Caso
            tenha duvidas sobre como utilizá-lo, clique abaixo para entrar em
            contato com a Filóo e saber mais.`
          }
        }
        
        if (servs && data.planServices && data.planServices.length) {
          data.planServices = data.planServices.filter((id) => servs.includes(id))
          data.valid = !!data.planServices.length

          if (!data.valid) {
            data.message = `Ops! Parece que você ja possui esse serviço! Caso
            tenha duvidas sobre como utilizá-lo, clique abaixo para entrar em
            contato com a Filóo e saber mais.`
          }
        }
      }

      return data
    }

    return false
  } catch (error) {
    return
  }
}
