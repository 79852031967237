import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)
const routes = {
  path: "",
  component: () =>
    import("../../../layouts/main/Main.vue"),
  children: [
    {
      path: "/fale-conosco",
      name: "fale-conosco",
      props: true,
      beforeEnter: (to, from, next) => {
        const menuName = "Ajuda";
        const bearer = Vue.prototype.$session.get("bearer");
        const menus = Vue.prototype.$session.get("menus-sidebar");
        // const customerData = Vue.prototype.$session.get("customerData")
        const isProjectPlan = Vue.prototype.$session.get("isProject")
          ? Vue.prototype.$session.get("isProject")
          : false;

        let accessAllowed = true;

        for (const m in menus) {
          if (menus[m].rota === menuName) {
            accessAllowed = menus[m].ativaRota;
          }
        }

        if (!bearer) {
          if (isProjectPlan) {
            next("/login-horas")
          } else {
            next("/login")
          }
        } else if (!accessAllowed) {
          next("/access-denied")
        } else {
          next()
        }
      },
      component: () =>
        import("./fale-conosco/fale-conosco.vue"),
    },
    {
      path: "/tutorial",
      name: "tutorial",
      props: true,
      beforeEnter: (to, from, next) => {
        const menuName = "Ajuda";
        const bearer = Vue.prototype.$session.get("bearer");
        const menus = Vue.prototype.$session.get("menus-sidebar");
        const isProjectPlan = Vue.prototype.$session.get("isProject") ? Vue.prototype.$session.get("isProject") : false;
        let accessAllowed = true;

        for (const m in menus) {
          if (menus[m].rota === menuName) {
            accessAllowed = menus[m].ativaRota;
          }
        }

        if (!bearer) {
          if (isProjectPlan) {
            next("/login-horas")
          } else {
            next("/login")
          }
        } else if (!accessAllowed) {
          next("/access-denied")
        } else {
          next()
        }
      },
      component: () =>
      import("./tutorial/tutorial.vue"),
    },
    {
      path: "/faq",
      name: "faq",
      props: true,
      beforeEnter: (to, from, next) => {
        const menuName = "Ajuda"
        const bearer = Vue.prototype.$session.get("bearer")
        const menus = Vue.prototype.$session.get("menus-sidebar")
        const isProjectPlan = Vue.prototype.$session.get("isProject") || false

        let accessAllowed = true

        for (const m in menus) {
          if (menus[m].rota === menuName) {
            accessAllowed = menus[m].ativaRota;
          }
        }

        if (!bearer) {
          if (isProjectPlan) {
            next("/login-horas")
          } else {
            next("/login")
          }
        } else if (!accessAllowed) {
          next("/access-denied")
        } else {
          next()
        }
      },
      component: () =>
        import("./faq/faq.vue"),
    },
  ],
}

export default [
  routes,
]

