export default {
  actionIcon: "StarIcon",
  highlightColor: "warning",
  data: [
    {
      index: 0,
      label: "Home",
      url: "/home",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 1,
      label: "Einstein Conecta",
      url: "/einstein-consulting-room",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 2,
      label: "Consultas solicitadas",
      url: "/pendency",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 3,
      label: "Consultas agendadas",
      url: "/scheduled",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 4,
      label: "Consultas realizadas",
      url: "/performed",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 5,
      label: "Consultas canceladas",
      url: "/cancelled",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 6,
      label: "Cartão virtual",
      url: "/filoo-card",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 7,
      label: "Medicamentos prescritos",
      url: "/prescription-drugs",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 8,
      label: "Medicamentos diversos",
      url: "/drug-research",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 9,
      label: "Farmácias",
      url: "/pharmacies",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 10,
      label: "Exames",
      url: "/laboratory-exams",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 11,
      label: "Laboratórios",
      url: "/laboratory-search",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 12,
      label: "Meus médicos/profissionais de Saúde",
      url: "/my-doctors",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 13,
      label: "Saldos e pagamentos",
      url: "/wallet",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 14,
      label: "Dados bancários",
      url: "/bank-details",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 15,
      label: "2ª via de boleto",
      url: "/bank-slip",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 16,
      label: "Dados cadastrais",
      url: "/profile",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 17,
      label: "Equipe médica por telefone 24x7",
      url: "/24h-health-center",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
    {
      index: 18,
      label: "Tutorial do cliente",
      url: "/tutorial",
      labelIcon: "ArrowRightCircleIcon",
      highlightAction: false,
    },
  ],
};
