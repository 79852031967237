const getters = {
  device (state) {
    if (state.isMobile) {
      return "mobile"
    }
    if (state.isTablet) {
      return "tablet"
    }
    return "desktop"
  },
  isMobile (state) {
    return state.isMobile
  },
  isTablet (state) {
    return state.isTablet
  },
  isDesktop (state) {
    return state.isDesktop
  },
  enviroment (state) {
    return state.isDev ? "dev" : "prod"
  },
  user (state) {
    return state.user
  },
  bodyOverlay (state) {
    return state.bodyOverlay
  },
  sidebarWidth (state) {
    return state.sidebarWidth
  },
  sidebarItems (state) {
    return state.sidebarItems
  },
  isThemeDark (state) {
    return state.theme === "dark"
  },
  customerBalance (state) {
    return state.updatedCustomerBalance
  },
  userServices (state) {
    return state.userServices
  },
  topmedUrl (state) {
    return state.topmedUrl
  },
  einsteinUrl (state) {
    return state.einsteinUrl
  },
}

export default getters
