import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)
const routes = {
  path: "/card-download",
  component: () =>
    import("./CardDownload.vue"),
  beforeEnter: (to, from, next) => {

    const bearer = Vue.prototype.$session.get("bearer");
    const isProjectPlan = Vue.prototype.$session.get("isProject") ? Vue.prototype.$session.get("isProject") : false;    

    let isAuthenticated = false;
    if (bearer !== null && bearer !== undefined && bearer !== "") {
      isAuthenticated = true;
    }
    else {
      isAuthenticated = false;
    }

    if (!isAuthenticated)
      if (isProjectPlan) next("/login-horas")
      else next("/login")    
    else next()

  },
}
export default [
  routes,
]

