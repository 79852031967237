const mutations = {

  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////

  UPDATE_SIDEBAR_WIDTH(state, width) {
    state.sidebarWidth = width
  },
  UPDATE_SIDEBAR_ITEMS_MIN(state, val) {
    state.sidebarItemsMin = val
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val
  },
  TOGGLE_CONTENT_OVERLAY(state, val) {
    state.bodyOverlay = val
  },
  TOGGLE_IS_SIDEBAR_ACTIVE(state, value) {
    state.isSidebarActive = value
  },
  UPDATE_THEME(state, val) {
    state.theme = val
  },
  UPDATE_WINDOW_BREAKPOINT(state, val) {
    state.breakpoint = val
  },
  UPDATE_PRIMARY_COLOR(state, val) {
    state.themePrimaryColor = val
  },
  UPDATE_DEVICE(state, width) {
    state.isMobile = width < 700 
    state.isTablet = width >= 700 && width < 990
    state.isDesktop = width >= 990
  },

  // ////////////////////////////////////////////
  // COMPONENT
  // ////////////////////////////////////////////

  // VxAutoSuggest
  UPDATE_STARRED_PAGE(state, payload) {
    // find item index in search list state
    const index = state.navbarSearchAndPinList.data.findIndex((item) => item.index == payload.index)
    // update the main list
    state.navbarSearchAndPinList.data[index].highlightAction = payload.val

    // if val is true add it to starred else remove
    if(payload.val) {
      state.starredPages.push(state.navbarSearchAndPinList.data[index])
    }else {
      // find item index from starred pages
      const index = state.starredPages.findIndex((item) => item.index == payload.index)
      // remove item using index
      state.starredPages.splice(index, 1)
    }
  },

  // The Navbar
  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10)
    state.starredPages = list.concat(starredPagesMore)
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp = false
    const lastItemInStarredLimited = state.starredPages[10]
    const starredPagesLimited = state.starredPages.slice(0, 10)
    state.starredPages = starredPagesLimited.concat(list)

    state.starredPages.slice(0,10).map((i) => {
      if(list.indexOf(i) > -1) downToUp = true
    })
    if(!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited)
    }
  },
  UPDATE_CUSTOMER_BALANCE(state, value) {
    state.updatedCustomerBalance = value
  },
  SET_ENVIROMENT(state, value) {
    state.isDev = value
  },
  SET_USER(state, user) {
    state.user = user
  },
  SET_USERSERVICES(state, userServices) {
    state.userServices = userServices
  },
  SET_SIDEBAR(state, sidebarItems) {
    state.sidebarItems = sidebarItems
  },
  SET_TOPMED_URL(state, topmedUrl) {
    state.topmedUrl = topmedUrl
  },
  SET_EINSTEIN_URL(state, einsteinUrl) {
    state.einsteinUrl = einsteinUrl
  },
}

export default mutations
