import { routes as auth } from "../modules/authentication";
import { routes as medicalConsultationRequest } from "../views/pages/medicalConsultationRequest";
import { routes as financial } from "../views/pages/financial";
import { routes as drugs } from "../views/pages/drugs";
import { routes as laboratories } from "../views/pages/laboratories";
import { routes as ajuda } from "../views/pages/ajuda";
import { routes as marketplace } from "../views/pages/marketplace";
import { routes as myDoctors } from "../views/pages/myDoctors";
import { routes as myPlan } from "../views/pages/myPlan";
import { routes as filooCard } from "../views/pages/filooCard";
import { routes as profile } from "../views/pages/profile";
import { routes as rating } from "../views/pages/rating";
import { routes as registerOfDependents } from "../views/pages/registerOfDependents";
import { routes as waitingRoom } from "../views/pages/waitingRoom";
import { routes as camTestRoom } from "../views/pages/camTestRoom";
import { routes as healthCenter24h } from "../views/pages/healthCenter24h";
import { routes as healthCenter24hTopmed } from "../views/pages/healthCenter24hTopmed";
import { routes as lifeInsurance } from "../views/pages/lifeInsurance";
import { routes as topmedPsycholSupport } from "../views/pages/topmedPsycholSupport";
import { routes as schedules } from "../views/pages/schedules";
import { routes as medicalRecord } from "../views/pages/patientMedicalRecord";
import { routes as quickAccess } from "../views/pages/quickAccess";
import { routes as consultingRoom } from "@/views/pages/consultingRoom";
import { routes as projectDetails } from "@/views/pages/projectDetails";
import { routes as cardDownload } from "@/views/pages/cardDownload";
import { routes as campaigns } from "@/views/campaigns";

export default [
  ...auth,
  ...campaigns,
  ...medicalConsultationRequest,
  ...financial,
  ...drugs,
  ...laboratories,
  ...ajuda,
  ...marketplace,
  ...myDoctors,
  ...myPlan,
  ...filooCard,
  ...profile,
  ...rating,
  ...registerOfDependents,
  ...waitingRoom,
  ...healthCenter24h,
  ...healthCenter24hTopmed,
  ...lifeInsurance,
  ...topmedPsycholSupport,
  ...schedules,
  ...medicalRecord,
  ...quickAccess,
  ...camTestRoom,
  ...consultingRoom,
  ...projectDetails,
  ...cardDownload,
]
