import Vue from "vue"
import App from "./App.vue"

import dotenv from "dotenv"
dotenv.config();

import api from "@/services/api"
import VueAxios from "vue-axios"
Vue.use(VueAxios, api)

import { Map, TileLayer, OsmSource, Geoloc } from "vuelayers"
import "./assets/css/vuelayers.css" // needs css-loader

Vue.use(Map)
Vue.use(TileLayer)
Vue.use(OsmSource)
Vue.use(Geoloc)

import * as VueGoogleMaps from "vue2-google-maps"
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAdwxi7P7SUDsbcxa3RRcQ315FDIOh1log",
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: "places,drawing"
    // OR: libraries: "places,drawing,visualization"
    //// If you want to set the version, you can do so:
    // v: "3.26",
  },
})

import VueCookies from "vue-cookies"
Vue.use(VueCookies)
VueCookies.config("2d")

import VueSession from "vue-session"
var options = {
  persist: true,
}
Vue.use(VueSession, options)

import VueTheMask from "vue-the-mask"
Vue.use(VueTheMask)

import money from "v-money"
Vue.use(money, {precision: 4})

import VueSwal from "vue-swal"
Vue.use(VueSwal)

import Vuesax from "vuesax"
import "material-icons/iconfont/material-icons.css" //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax)

import Chat from "vue-beautiful-chat"
Vue.use(Chat)

import "../themeConfig.js"

import "./globalComponents.js"

import "./assets/scss/main.scss"

import "@/assets/css/main.css";

import router from "./router/router"

import store from "./store/store"

import "./filters/filters"

import { VueHammer } from "vue2-hammer"
Vue.use(VueHammer)

import "prismjs"
import "prismjs/themes/prism-tomorrow.css"

import JsonCSV from "vue-json-csv"
Vue.component("downloadCsv", JsonCSV)
import JsonExcel from "vue-json-excel"

// Vue-GTM-Custom
import VueGtm from "vue-gtm-custom";
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM,
  defer: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
})

import "./registerServiceWorker"
Vue.component("downloadExcel", JsonExcel)

require("./assets/css/iconfont.css")

import StarRating from "vue-star-rating"
Vue.use(StarRating);
Vue.component("star-rating", StarRating);

//VueMeta Meta Tags https://www.npmjs.com/package/vue-meta
import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  keyName: "metaInfo",
  attribute: "data-vue-meta",
  ssrAttribute: "data-vue-meta-server-rendered",
  tagIDKeyName: "vmid",
  refreshOnceOnNavigation: true,
})

import "vue-multiselect/dist/vue-multiselect.min.css"

import { BootstrapVue, IconsPlugin, BootstrapVueIcons, SpinnerPlugin } from "bootstrap-vue"
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)
Vue.use(SpinnerPlugin)
Vue.config.productionTip = false

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css"

// Sentry
import * as Sentry from "@sentry/vue"

if (process.env.VUE_APP_SENTRY_DSN_URL) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN_URL,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

//Clarity
import Clarity from "./plugins/clarity";
Vue.use(Clarity)

Vue.prototype.loader = (show = false) => {
  const loader = document.getElementById("loading-bg");
  if (show) {
    loader.classList.add("navigator");
    loader.style.display = "block";
    setTimeout(() => {
      loader.classList.add("show");
    });
  } else {
    loader.classList.remove("show");
    setTimeout(() => {
      loader.style.display = "none";
      loader.classList.remove("navigator");
    }, 250);
  }
};

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")
