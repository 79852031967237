import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)
const routes = {
  path: "",
  component: () =>
        import("../../../layouts/main/Main.vue"),
  children: [
    {
      path: "/cam-test-room/:medRecId",
      name: "cam-test-room",
      beforeEnter: (to, from, next) => {
        const menuName = "Consultas";
        const bearer = Vue.prototype.$session.get("bearer");
        const menus = Vue.prototype.$session.get("menus-sidebar");
        let accessAllowed = true;

        for (const m in menus) {
          if (menus[m].rota === menuName) {
            accessAllowed = menus[m].ativaRota;
          }
        }
        let isAuthenticated = false;
        if (bearer !== null && bearer !== undefined && bearer !== "") {
          isAuthenticated = true;
        }
        else {
          isAuthenticated = false;
        }

        if (!isAuthenticated) next("/login")
        else if (!accessAllowed) next("/access-denied")
        else next()
      },
      component: () =>
            import("./camTestRoom.vue"),
    },

  ],
}
export default [
  routes,
]

