import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)
const routes = {
  path: "",
  component: () =>
        import("../../../layouts/main/Main.vue"),
  children: [
    {
      path: "/consulting-room/:medRecId",
      name: "consulting-room",
      beforeEnter: (to, from, next) => {
        const menuName = "Consultas";
        const bearer = Vue.prototype.$session.get("bearer");
        const menus = Vue.prototype.$session.get("menus-sidebar");
        const isProjectPlan = Vue.prototype.$session.get("isProject") ? Vue.prototype.$session.get("isProject") : false;
        let accessAllowed = true;

        for (const m in menus) {
          if (menus[m].rota === menuName) {
            accessAllowed = menus[m].ativaRota;
          }
        }
        let isAuthenticated = false;
        if (bearer !== null && bearer !== undefined && bearer !== "") {
          isAuthenticated = true;
        }
        else {
          isAuthenticated = false;
        }

        if (!isAuthenticated)
          if (isProjectPlan) next("/login-horas")
          else next("/login")
        else if (!accessAllowed) next("/access-denied")
        else next()
      },
      component: () =>
            import("./consultingRoom.vue"),
    },
    {
      path: "/einstein-consulting-room",
      name: "einstein-consulting-room",
      beforeEnter: (to, from, next) => {
        const menuName = "Consultas";
        const bearer = Vue.prototype.$session.get("bearer");
        const menus = Vue.prototype.$session.get("menus-sidebar");
        const isProjectPlan = Vue.prototype.$session.get("isProject") ? Vue.prototype.$session.get("isProject") : false;
        const hasEinsteinPermission = Vue.prototype.$session.get("servicesRoutes").includes("/einstein-consulting-room")
        let accessAllowed = true;

        for (const m in menus) {
          if (menus[m].rota === menuName) {
            accessAllowed = menus[m].ativaRota;
          }
        }
        let isAuthenticated = false;
        if (bearer !== null && bearer !== undefined && bearer !== "") {
          isAuthenticated = true;
        }
        else {
          isAuthenticated = false;
        }

        if (!isAuthenticated)
          if (isProjectPlan) next("/login-horas")
          else next("/login")
        else if (!accessAllowed) next("/access-denied")
        else if (!hasEinsteinPermission) next("/upgrade-required")
        else next()
      },
      component: () =>
            import("./einsteinConecta/einsteinConectaRoom.vue"),
    },
    {
      path: "/topmed-consulting-room",
      name: "topmed",
      beforeEnter: (to, from, next) => {
        const menuName = "Consultas"
        const bearer = Vue.prototype.$session.get("bearer")
        const menus = Vue.prototype.$session.get("menus-sidebar")
        const isProjectPlan = Vue.prototype.$session.get("isProject") ? Vue.prototype.$session.get("isProject") : false

        const hasTopmedPermission = Vue.prototype.$session.get("servicesRoutes").includes("/topmed-consulting-room")

        let accessAllowed = true

        for (const m in menus) {
          if (menus[m].rota === menuName) {
            accessAllowed = menus[m].ativaRota;
          }
        }

        if (!bearer) {
          next(isProjectPlan ? "/login-horas" : "/login")
        } else if (!accessAllowed) {
          next("/access-denied")
        } else if (!hasTopmedPermission) {
          next("/upgrade-required")
        } else {
          next()
        }
      },
      component: () =>
            import("./topmed/topmedRoom.vue"),
    },
    {
      path: "/topmed-chat",
      name: "topmed",
      beforeEnter: (to, from, next) => {
        const menuName = "Consultas";
        const bearer = Vue.prototype.$session.get("bearer");
        const menus = Vue.prototype.$session.get("menus-sidebar");
        const isProjectPlan = Vue.prototype.$session.get("isProject") ? Vue.prototype.$session.get("isProject") : false;

        const hasTopmedPermission = Vue.prototype.$session.get("servicesRoutes").includes("/topmed-consulting-room")

        let accessAllowed = true;

        for (const m in menus) {
          if (menus[m].rota === menuName) {
            accessAllowed = menus[m].ativaRota;
          }
        }
        let isAuthenticated = false;
        if (bearer !== null && bearer !== undefined && bearer !== "") {
          isAuthenticated = true;
        }
        else {
          isAuthenticated = false;
        }

        if (!isAuthenticated)
          if (isProjectPlan) next("/login-horas")
          else next("/login")
        else if (!accessAllowed) next("/access-denied")
        else if (!hasTopmedPermission) next("/upgrade-required")
        else next()
      },
      component: () =>
            import("./topmed/topmed.vue"),
    },

  ],
}
export default [
  routes,
]