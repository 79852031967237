<template>
  <main class="campaigns">
    <header class="campaigns__header">
      <img
        alt="Logomarca da filóo saúde"
        src="@/assets/logo-color.svg"
      />
    </header>

    <transition>
      <router-view />
    </transition>

    <footer class="campaigns__footer">
      <div class="campaigns__footer-copyright">
        Filóo Saúde Ltda ©{{ new Date().getFullYear() }}.
        <br v-if="device === 'mobile'">
        Todos os direitos reservados.
      </div>
    </footer>
  </main>
</template>

<script>
import { useStore } from "@/store/store.js"
import { computed } from "vue"

export default {
  name: "Campaigns",
  
  setup () {
    const store = useStore()

    return {
      device: computed(() => store.getters.device),
    }
  },
};
</script>

<style lang="scss" scoped>
.campaigns {
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 4.5rem 0 1.5rem;

  &__header {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 2.5rem;
  }

  &__footer {
    margin-top: 3.25rem;

    &-copyright {
      font-family: Inter;
      font-style: normal;
      text-align: center;
    }
  }
}
</style>

