export default [
  {
    path: "/rating/:idMedicalRecord/:token",
    name: "rating",
    component: () =>
            import("./rating.vue"),
  },

]

