import navbarSearchAndPinList from "@/layouts/components/navbarSearchAndPinList"
import themeConfig, { colors } from "@/../themeConfig.js"

const state = {
  breakpoint: null,
  bodyOverlay: false,
  theme: themeConfig.theme || "light",
  navbarSearchAndPinList: navbarSearchAndPinList,
  appActiveUser: {
    id: 0,
    name: "Mr Dev",
    about: "About info here",
    img: "avatar-s-11.png",
    status: "online",
  },

  filooServer: process.env.VUE_APP_BASE_URL_BACK,
  iuguNotifyAccountId: process.env.VUE_APP_IUGU_NOTIFY_ACCOUNT_ID,
  iuguTestMode: process.env.VUE_APP_IUGU_TEST_MODE !== "false",
  googleMapUrl: process.env.VUE_APP_GMAPS_URL,
  googleMapKey: process.env.VUE_APP_GMAPS_KEY,
  themePrimaryColor: colors.primary,
  filooPortalClientUrl: process.env.VUE_APP_FILOO_CLIENT_URL,

  starredPages: navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  userRole: null,

  // Sidebar Scope
  isSidebarActive: true,
  reduceButton: themeConfig.sidebarCollapsed,
  sidebarItems: [],
  sidebarItemsMin: false,
  sidebarLogo: "logo-dasa.png",
  sidebarWidth: "default",

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar component
  user: null,
  userServices: [],
  isDev: false,
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  topmedUrl: null,
  einsteinUrl: null,
  updatedCustomerBalance: false,
};

export default state;
