import Vue from "vue"
import Router from "vue-router"
import LayoutCampaign from "@/layouts/campaigns/Campaigns"

import { useStore } from "@/store/store.js"
import { checkCampaignElegibility } from "@/helpers/campaigns/elegibility";

Vue.use(Router)
const routes = [
  {
    path: "/campaigns",
    name: "Campaigns",
    component: LayoutCampaign,
    children: [
      {
        path: "activation",
        name: "CampaignsActivation",
        meta: {
          campaign: true,
          plans: { dev: [336,337], prod: [660, 661] },
          services: { dev: [9], prod: [25] },
        },
        props: true,
        beforeEnter: async (to, from, next) => {
          const bearer = Vue.prototype.$session.get("bearer")

          if (!bearer) {
            Vue.prototype.$session.set("redirect", { name: "CampaignsActivation" })
            Vue.prototype.$session.set("campaign", to.meta)
    
            return next("/login")
          }
          
          if (from.path !== "/" && from.path !== "/login" && from.name !== "CampaignsPayment") {
            const store = useStore()
            const planIds = to.meta.plans[store.getters.env]

            const elegibilityResponse = await checkCampaignElegibility(
              Vue.prototype.$session.get("patientId"),
              { plans: planIds }
            )
     
            if (!elegibilityResponse || !elegibilityResponse.isElegible) {
              return next({ name: "plan-upgrade" })
            }
          }

          return next()
        },
        component: () =>
          import("./Activation.vue"),
      },      
      {
        path: "/campaigns/:campaign/payment",
        name: "CampaignsPayment",
        props: true,
        meta: { campaign: true },
        beforeEnter: (to, from, next) => {
          // has to change in future because the campaign name will be validated
          // with the backend

          if (!from.meta.campaign) {
            return next(from.path)
          }

          const bearer = Vue.prototype.$session.get("bearer");

          if (!bearer) {
            Vue.prototype.$session.set("redirect", to.path)
            Vue.prototype.$session.set("campaign", true)

            return next("/login")
          }

          return next()
        },
        component: () =>
          import("../../modules/authentication/pages/payment/payment.vue"),
      },
    ],
  },
  {
    path: "",
    component: () =>
      import("../../layouts/main/Main.vue"),
    children: [
      {
        path: "/campaigns",
        name: "NewCampaigns",
        props: true,
        meta: { campaign: true },
        beforeEnter: (to, from, next) => {
          console.log("campaigns")

          const menuName = "Meu Plano";
          const bearer = Vue.prototype.$session.get("bearer");
          const menus = Vue.prototype.$session.get("menus-sidebar");
          const customerData = Vue.prototype.$session.get("customerData")
          const isProjectPlan = Vue.prototype.$session.get("isProject")
            ? Vue.prototype.$session.get("isProject")
            : false;
          
          const companyId = customerData ? customerData.companyId : false

          let accessAllowed = true;

          for (const m in menus) {
            if (menus[m].rota === menuName) {
              accessAllowed = menus[m].ativaRota;
            }
          }

          const isAuthenticated = !!bearer;

          Vue.prototype.$session.set("campaign", false)

          if (!isAuthenticated) {
            Vue.prototype.$session.set("redirect", "/campaigns")

            if (isProjectPlan) next("/login-horas")
            else next("/login")
          }
          else if (!accessAllowed) next("/access-denied")
          else if (companyId && companyId !== 2) next("/home")
          else next()
        },
        component: () =>
          import("@/views/pages/myPlan/planUpgrade/optionsScreen/optionsScreen.vue"),
      },
    ],
  },
]

export default [
  ...routes,
]

