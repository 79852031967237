import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)
const routes = {
  path: "",
  component: () =>
        import("../../../layouts/main/Main.vue"),
  children: [
    {
      path: "/laboratory-exams",
      name: "laboratory-exams",
      beforeEnter: (to, from, next) => {
        const bearer = Vue.prototype.$session.get("bearer");
        const servicesRoutes = Vue.prototype.$session.get("servicesRoutes")
        const hasLabPermission = servicesRoutes.includes("/solicitation-and-exams")

        if (!bearer) {
          if (Vue.prototype.$session.get("isProject")) {
            next("/login-horas")
          } else {
            next("/login")
          }
        } else if (!hasLabPermission) {
          next("/upgrade-required")
        } else {
          next()
        }

      },
      component: () =>
                import("./laboratoryExams/laboratoryExams.vue"),
    },
    {
      path: "/laboratory-search",
      name: "laboratory-search",
      beforeEnter: (to, from, next) => {
        const menuName = "Laboratórios";
        const bearer = Vue.prototype.$session.get("bearer");
        const menus = Vue.prototype.$session.get("menus-sidebar");
        const isProjectPlan = Vue.prototype.$session.get("isProject") ? Vue.prototype.$session.get("isProject") : false;
        let accessAllowed = true;

        for (const m in menus) {
          if (menus[m].rota === menuName) {
            accessAllowed = menus[m].ativaRota;
          }
        }
        let isAuthenticated = false;
        if (bearer !== null && bearer !== undefined && bearer !== "") {
          isAuthenticated = true;
        }
        else {
          isAuthenticated = false;
        }
        if (!isAuthenticated)
          if (isProjectPlan) next("/login-horas")
          else next("/login")
        else if (!accessAllowed) next("/access-denied")
        else next()
      },
      component: () =>
                import("./laboratorySearch/laboratorySearch.vue"),
    },
  ],
}
export default [
  routes,
]

