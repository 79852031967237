import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)
const routes = {
  path: "",
  component: () =>
        import("../../../layouts/main/Main.vue"),
  children: [
    {
      path: "/register-of-dependents",
      name: "register-of-dependents",
      beforeEnter: (to, from, next) => {
        const menuName = "Cadastro de beneficiários";
        const bearer = Vue.prototype.$session.get("bearer");
        const menus = Vue.prototype.$session.get("menus-sidebar");
        const isProjectPlan = Vue.prototype.$session.get("isProject") ? Vue.prototype.$session.get("isProject") : false;
        let accessAllowed = true;
        for (const m in menus) {
          if (menus[m].rota === menuName) {
            accessAllowed = menus[m].ativaRota;
          }
        }
        let isAuthenticated = false;
        if (bearer !== null && bearer !== undefined && bearer !== "") {
          isAuthenticated = true;
        }
        else {
          isAuthenticated = false;
        }

        if (!isAuthenticated) 
          if (isProjectPlan) next("/login-horas")
          else next("/login")
        else if (!accessAllowed) next("/access-denied")
        else next()

      },
      component: () =>
                import("./registerOfDependents.vue"),
    },
  ],
}
export default [
  routes,
]

