import Vue from "vue"
import Axios from "axios"
import baseSidebar from "@/constants/sidebar.js"
import axios from "@/services/api"

const actions = {

  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////

  updateSidebarWidth({ commit }, width) {
    commit("UPDATE_SIDEBAR_WIDTH", width);
  },
  toggleContentOverlay({ commit }) {
    commit("TOGGLE_CONTENT_OVERLAY");
  },
  updateTheme({ commit }, val) {
    commit("UPDATE_THEME", val);
  },
  async updateWindowWidth({ commit }, width) {
    await commit("UPDATE_DEVICE", width)
  },

  // ////////////////////////////////////////////
  // COMPONENT
  // ////////////////////////////////////////////

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit("UPDATE_STARRED_PAGE", payload)
  },

  //  The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_LIMITED", list)
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_MORE", list)
  },
  setEnviroment({ commit }, url) {
    const isLocal = url.indexOf("localhost") > 0
    const isDev = url.indexOf("//dev")
    const isHomol = url.indexOf("//homolog")

    commit("SET_ENVIROMENT", isLocal || isDev || isHomol)
  },

  async getPlanByCode({ state }, code) {
    const endPoint = `customerplan/find_by_code/${code}`

    const response = await Axios.get(
      `${state.filooServer}${endPoint}`,
      { headers: { Authorization: Vue.prototype.$session.get("bearer") }}
    )
      .catch((error) => (
        console.error({
          error,
          endpoint: endPoint,
          method: "find_by_code",
        })
      ))

    return response && response.data ? response.data : null
  },

  async getPlanById({ state }, id) {
    const response = await Axios.get(
      `${state.filooServer}customerplan/find_by_id/${id}`,
      { headers: { Authorization: Vue.prototype.$session.get("bearer") }}
    )
      .catch((error) => (
        console.error({
          error,
          endpoint: `${state.filooServer}customerplan/find_by_id/${id}`,
          method: "find_by_id",
        })
      ))

    return response && response.data ? response.data : null
  },

  async getAddressByZipcode({ state }, cep) {
    const { status, data, error } = await Axios.get(`${state.filooServer}cep/${cep}`)
      .catch((error) => error.response.data)

    if (error) {
      console.error(error)
    }

    return status === 200 ? data : null
  },

  async getGoogleAddress({ state }, { cep, lat, lng }) {
    const url = [`key=${state.googleMapKey}`]

    if (cep) {
      url.push(`address=${cep}`)
    }
    if (lat && lng) {
      url.push(`latlng=${lat},${lng}`)
    }

    const { status, data } = await Axios.get(
      `${state.googleMapUrl}api/geocode/json?${url.join("&")}`
    )

    return status === 200 && data.status === "OK" ? data.results[0] : null
  },

  async getMe({ state, dispatch, commit }) {
    const { status, data: user } = await Axios.get(
      `${state.filooServer}me`,
      { headers: { Authorization: Vue.prototype.$session.get("bearer") }}
    ).catch((error) => {
      this.showNotifyError("Não foi possível buscar os dados do usuário", 5000)

      console.error({
        error: error,
        endpoint: `${state.filooServer}me`,
        method: "getMe",
        http_method: "GET",
      })
    })

    await commit("SET_USER", status === 200 ? user : null)

    await dispatch("updateSidebar", user)
  },

  async getUserServices({ state, commit }, id) {
    const { status, data } = await Axios.get(
      `${state.filooServer}patient/services/${id}`,
      { headers: { Authorization: Vue.prototype.$session.get("bearer") }}
    ).catch((error) => {
      this.showNotifyError(
        "Não foi possível obter os serviços adquiridos pelo usuário!",
        5000
      )

      console.error({
        error,
        endpoint: `${state.filooServer}patient/services/${id}`,
        method: "getMe",
        http_method: "GET",
      })
    })

    commit("SET_USERSERVICES",
      status === 200
        ? [
          ...data.acquiredPlanServices.map((plan) => ({
            ...plan,
            acquired: true,
          })),
          ...data.missingPlanServices.map((plan) => ({
            ...plan,
            acquired: false,
          })),
        ]
        : null
    )
  },

  async getUserWallet({ state }) {
    const { status, data } = await Axios.get(
      `${state.filooServer}wallet/overview`,
      { headers: { Authorization: Vue.prototype.$session.get("bearer") }}
    ).catch((error) => console.error({
      error: error,
      endpoint: `${state.filooServer}wallet/overview`,
      method: "getMe",
      http_method: "GET",
    }))

    if (status === 200) {
      Vue.prototype.$session.set("saldo", data.avaliableBalance)

      return data
    }
  },

  async setScheduleConfirmation({ state }, { id, attendanceIndex, attendanceType }) {
    const { status, data } = await Axios.put(
      `${state.filooServer}schedules/${id}/accept-patient-horario/${attendanceIndex}`,
      { tipoAgendamento: attendanceType },
      { headers: { Authorization: Vue.prototype.$session.get("bearer") }}
    )
      .catch((error) => error.response)

    return { data, success: status === 200, status }
  },

  async updateSidebar({ state, commit, dispatch }, data) {
    
    const toogleShowItem = (name, value) => {
      if (name.indexOf(".") > 0) {
        const [parent] = name.split(".")
        const item = sidebar.find((itm) => itm.slug === parent)

        if (item.items) {
          const subItem = item.items.find((itm) => itm.slug === name)
          subItem.isPresented = value
        }
      } else {
        sidebar.find((item) => item.slug === name).isPresented = value
      }
    }

    const sidebar = JSON.parse(JSON.stringify(baseSidebar))
    const user = data || state.user
    const plan = user.customerPlan

    if (plan && plan.planServices) {
      commit("SET_USERSERVICES", plan.planServices)
    } else {
      await dispatch("getUserServices", user.patientId)
    }

    const userServices = state.userServices || []
    const servicesRoutes = userServices.filter(srvc => srvc.route).map(srvc => srvc.route)
    // const servicesName = userServices.filter((plan) => plan.active).map((plan) => plan.name)

    const rules = {
      NewSchedule: servicesRoutes.includes("/solicitation-and-exams"),
      EmergencyCareTopmed: servicesRoutes.includes("/pav-symptoms"),
      EmergencyCareEinstein: servicesRoutes.includes("/einstein-consulting-room"),
      Schedules: {
        Pendency: true,
        Scheduled: true,
        Performed: true,
        Cancelled: true,
        MyDoctors: true,
      },
      VirtualCard: user.isProject || servicesRoutes.includes("/filoo-card"),
      HealthCenter: servicesRoutes.includes("/24h-health-center"),
      LifeInsurance: servicesRoutes.includes("/life-insurance"),
      ProjectDetails: !!user.isProject,
      DiscountsClub: false,
      MyServices: {
        ConsultingRoom: servicesRoutes.includes("/topmed-consulting-room"),
        EmergencyCareDasa: true,
        EmergencyCareDasaTelemed: true,
        Laboratories: true,
        DasaPharmacies: true,
        Discount: false,
        HealthChat: servicesRoutes.includes("/health-chat"),
        HealthCenter24h: servicesRoutes.includes("/24h-health-center-topmed"),
        PsychologySupport: servicesRoutes.includes("/topmed-psychol-support"),
        Surgerys: servicesRoutes.includes("/surgerys"),
      },
      MyPlan: {
        Upgrade: !user.isB2B,
        Dependents: parseInt(user.planTypeId) === 1,
        Wallet: true,
        BankDetails: true,
      },
      Help: {
        Faq: true,
        Tutorial: true,
        ContactUs: true,
      },
    }

    Object.keys(rules).forEach((key) => {
      if (typeof rules[key] === "boolean") {
        toogleShowItem(key, rules[key])
      } else {
        const group = rules[key]
        toogleShowItem(key, Object.values(group).some(val => val))

        Object.keys(group).forEach((gkey) => {
          toogleShowItem(`${key}.${gkey}`, group[gkey])
        })
      }
    })

    commit("SET_SIDEBAR", sidebar)
  },
  
  async getTopmedUrl({ state, commit }) {
    const response = await axios.get(`${state.filooServer}topmed/room`)
      .catch((error) => 
        console.error({
          error: error,
          endpoint: `${state.filooServer}topmed/room`,
          http_method: "GET",
        })
      )
      
    await commit(
      "SET_TOPMED_URL",
      !response || response.status !== 200 ? null : response.data.Url,
    )
  },

  async getEinsteinUrl({ state, commit }) {
    const response = await Axios.get(
      `${state.filooServer}einstein/create`,
      { headers: { Authorization: Vue.prototype.$session.get("bearer") }}
    )
      .catch((error) => 
        console.error({
          error: error,
          endpoint: `${state.filooServer}einstein/create`,
          http_method: "GET",
        })
      )
      
    await commit(
      "SET_EINSTEIN_URL",
      !response || response.status !== 200 ? null : response.data.url,
    )
  },

  async getUserDataByCpf({ state }, cpf) {
    const response = await Axios.get(
      `${state.filooServer}signup?cpf=${cpf}&type=PATIENT`,
    )
      .catch((error) => 
        console.error({
          error: error,
          endpoint: `${state.filooServer}signup?cpf=${cpf}&type=PATIENT`,
          http_method: "GET",
        })
      )
    
    return !response || response.status !== 200 ? null : response.data
  },
}

export default actions
