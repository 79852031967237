export default [
  {
    url: "/home",
    name: "Home",
    slug: "Home",
    icon: "home",
    isPresented: true,
  },
  {
    url: "/home",
    name: "Nova Consulta",
    slug: "NewSchedule",
    icon: "video_call",
    isPresented: true,
  },
  {
    name: "Pronto Atendimento",
    slug: "EmergencyCareTopmed",
    icon:"insert_invitation",
    isModal: true,
    onclick: "popupCancelamento = true",
    isPresented: true,
  },
  {
    url: "/einstein-consulting-room",
    name: "Pronto Atendimento",
    slug: "EmergencyCareEinstein", // Einstein Conecta
    icon: "insert_invitation",
    isPresented: true,
  },
  {
    name: "Consultas",
    slug: "Schedules",
    icon: "access_time",
    i18n: "Appointments",
    isPresented: true,
    items: [
      {
        url: "/pendency",
        name: "Solicitadas",
        slug: "Schedules.Pendency",
        icon: "play_arrow",
        isPresented: true,
      },
      {
        url: "/scheduled",
        name: "Agendadas",
        slug: "Schedules.Scheduled",
        icon: "play_arrow",
        isPresented: true,
      },
      {
        url: "/performed",
        name: "Realizadas",
        slug: "Schedules.Performed",
        icon: "play_arrow",
        isPresented: true,
      },
      {
        url: "/cancelled",
        name: "Canceladas",
        slug: "Schedules.Cancelled",
        icon: "play_arrow",
        isPresented: true,
      },
      {
        url: "/my-doctors",
        name: "Meus Médicos",
        slug: "Schedules.MyDoctors",
        icon: "local_hospital",
        isPresented: true,
      },
    ],
  },
  {
    url: "/filoo-card",
    name: "Cartão Virtual",
    slug: "VirtualCard",
    icon: "credit_card",
    isPresented: true,
  },
  {
    url: "/prescription-drugs",
    name: "Clube de Descontos",
    slug: "DiscountsClub",
    icon: "healing",
    isPresented: false,
    items: [],
  },
  // {
  //   url: "/topmed-consulting-room",
  //   name: "Psicólogo na Tela",
  //   slug: "PsychologistTopmed",
  //   icon: "add_to_queue",
  //   isPresented: true,
  // },
  {
    name: "Meus Serviços",
    slug: "MyServices",
    icon: "local_pharmacy",
    isPresented: true,
    items:[
      {
        url: "/topmed-consulting-room",
        name: "Fale com um Especialista",
        slug: "MyServices.ConsultingRoom",
        icon: "insert_invitation",
        isPresented: true,
      },
      {
        name: "Pronto Atendimento Virtual",
        slug: "MyServices.EmergencyCareDasa",
        icon: "insert_invitation",
        isModal: true,
        isPresented: true,
      },
      {
        name: "Agendamento Telemedicina",
        slug: "MyServices.EmergencyCareDasaTelemed",
        icon: "insert_invitation",
        isModal: true,
        isPresented: true,
      },
      {
        url: "/pharmacies-topmed",
        name: "Farmácias",
        slug: "MyServices.Discount",
        isPresented: true,
      },
      {
        url: "/pharmacies",
        name: "Farmácias",
        slug: "MyServices.DasaPharmacies",
        isPresented: true,
      },
      {
        url: "/laboratory-search",
        name: "Laboratórios",
        slug: "MyServices.Laboratories",
        icon: "colorize",
        isPresented: true,
      },
      {
        name: "Cirurgias",
        slug: "MyServices.Surgerys",
        icon: "colorize",
        isModal: true,
        isPresented: true,
      },
      {
        url: "/topmed-chat",
        name: "Chat Saúde",
        slug: "MyServices.HealthChat",
        isPresented: true,
      },
      {
        url: "/24h-health-center-topmed",
        name: "Equipe Médica Por Telefone",
        slug: "MyServices.HealthCenter24h",
        isPresented: true,
      },
      {
        url: "/topmed-psychol-support",
        name: "Saúde Emocional",
        slug: "MyServices.PsychologySupport",
        isPresented: true,
      },
    ],
  },
  {
    url: "/meu-plano",
    name: "Minha Assinatura",
    slug: "MyPlan",
    icon: "monetization_on",
    isPresented: true,
    items: [
      {
        url: "/plan-upgrade",
        name: "Meu Plano",
        slug: "MyPlan.Upgrade",
        i18n: "plan-upgrade",
        isPresented: true,
      },
      {
        url: "/register-of-dependents",
        name: "Meus Dependentes",
        slug: "MyPlan.Dependents",
        isPresented: true,
      },
      {
        url: "/wallet",
        name: "Pagamentos",
        slug: "MyPlan.Wallet",
        i18n: "wallet",
        isPresented: true,
      },
      {
        url: "/bank-details",
        name: "Dados Bancários",
        slug: "MyPlan.BankDetails",
        i18n: "bank-details",
        isPresented: true,
      },
    ],
  },
  {
    url: "/24h-health-center",
    name: "Equipe médica por telefone 24x7",
    slug: "HealthCenter",
    icon: "local_convenience_store",
    iconPack: "material-icons",
    featherIcon: false,
    isPresented: true,
  },
  {
    url: "/life-insurance",
    name: "Pacote de Seguros e Assistências",
    slug: "LifeInsurance",
    icon: "rocket",
    iconPack: "material-icons",
    featherIcon: false,
    isPresented: true,
  },
  {
    url: "/profile",
    name: "Meu Perfil",
    slug: "Profile",
    icon: "person_pin",
    isPresented: true,
  },
  {
    name: "Ajuda",
    slug: "Help",
    icon: "contact_support",
    isPresented: true,
    items: [
      {
        url: "/faq",
        name: "FAQ",
        slug: "Help.Faq",
        i18n: "faq",
        isPresented: true,
      },
      {
        url: "/tutorial",
        name: "Help",
        slug: "Help.Tutorial",
        i18n: "ajuda",
        isPresented: true,
      },
      {
        url: "https://wa.me/5511974640527?text&type=phone_number&app_absent=0",
        name: "Fale Conosco",
        slug: "Help.ContactUs",
        i18n: "fale-conosco",
        isPresented: true,
        isExternal: true,
        target: "_blank",
      },
    ],
  },
  {
    url: "/project-details",
    name: "Informações do Projeto",
    slug: "ProjectDetails",
    icon: "article",
    isPresented: true,
  },
  {
    url: "/logout",
    name: "Sair",
    slug: "Logout",
    icon: "exit_to_app",
    isPresented: true,
  },
]
